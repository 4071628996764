import React from "react";
import {
  FaWhatsapp,
  FaFacebookF,
  FaInstagram,
  FaSpotify,
  FaGoogle,
  FaYoutube,
} from "react-icons/fa";

import {
  Background,
  Container,
  Logo,
  ContentContainer,
  Content,
  Title,
  Subtitle,
  Button,
  Schedules,
  Text,
  Address,
  MediasSociais,
} from "./styles";
import logo from "../../assets/images/logo.png";

function Home() {
  return (
    <Background>
      <Container>
        <ContentContainer>
          <Content>
            <Logo>
              <img src={logo} alt="Agridoce" />
            </Logo>
            <Title>
              <h1>
                Peça nossos produtos
                <br />
                por Delivery!
              </h1>
            </Title>
            {/* <Subtitle>
              <span>Enquanto isso, peça nossos produtos por Delivery!</span>
            </Subtitle> */}
            <Button>
              <a
                href="https://api.whatsapp.com/send?phone=5524999778760"
                target="_blank"
                alt="WhatsApp"
              >
                <FaWhatsapp />
                (24) 99977-8760
              </a>
            </Button>
            <Schedules>
              <span>Seg à sex das 9 às 19h | Sáb das 9 às 14h</span>
            </Schedules>
            <Text>
              <p>Você também pode dar uma passadinha em nossa Quitanda</p>
            </Text>
            <Address>
              <span>
                Av. 17 de Julho, 276 - Aterrado, Volta Redonda - RJ CEP:
                27213-200
              </span>
              <a
                href="https://www.google.com/maps/place/Hortifruti+Delivery+Agridoce/@-22.5054881,-44.0913952,15z/data=!4m5!3m4!1s0x0:0x2064bb2d81d9f2bb!8m2!3d-22.5054881!4d-44.0913952"
                target="_blank"
              >
                ver mapa
              </a>
            </Address>
            <MediasSociais>
              <span>
                <a
                  href="https://www.facebook.com/agridocehortifruti"
                  target="_blank"
                  alt="Agridoce Hortifruti Facebook"
                  title="Agridoce Hortifruti Facebook"
                >
                  <FaFacebookF />
                </a>
              </span>
              <span>
                <a
                  href="https://www.instagram.com/agridocehortifruti"
                  target="_blank"
                  alt="Agridoce Hortifruti Instagram"
                  title="Agridoce Hortifruti Instagram"
                >
                  <FaInstagram />
                </a>
              </span>

              <span>
                <a
                  href="https://open.spotify.com/user/ybup71r97yf9a11mt4l2iz2yf?si=8uPo8aJcQJqKR_pO_OHZTw"
                  target="_blank"
                  alt="Agridoce Hortifruti Spotify"
                  title="Agridoce Hortifruti Spotify"
                >
                  <FaSpotify />
                </a>
              </span>

              <span>
                <a
                  href="https://g.page/agridocehortifruti?we"
                  target="_blank"
                  alt="Agridoce Hortifruti Google"
                  title="Agridoce Hortifruti Google"
                >
                  <FaGoogle />
                </a>
              </span>

              <span>
                <a
                  href="https://www.youtube.com/channel/UC2P-EtO8xGBhUYM0zZdPrVA"
                  target="_blank"
                  alt="Agridoce Hortifruti Youtube"
                  title="Agridoce Hortifruti Youtube"
                >
                  <FaYoutube />
                </a>
              </span>
            </MediasSociais>
          </Content>
        </ContentContainer>
      </Container>
    </Background>
  );
}

export default Home;
