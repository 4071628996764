import {createGlobalStyle} from 'styled-components'
import MuseoSlab300 from '../assets/fonts/Museo_Slab_300.otf';
import MuseoSlab500 from '../assets/fonts/Museo_Slab_500.otf';
import MuseoSlab700 from '../assets/fonts/Museo_Slab_700.otf';
import MuseoSlab900 from '../assets/fonts/Museo_Slab_900.otf';


export default createGlobalStyle`

*{
  margin: 0;
  padding: 0;
  outline: 0;
  box-sizing: border-box;
}

@font-face {
  font-family: 'MuseoSlab300';
  src: url(${MuseoSlab300});
}

@font-face {
  font-family: 'MuseoSlab500';
  src: url(${MuseoSlab500});
}

@font-face {
  font-family: 'MuseoSlab700';
  src: url(${MuseoSlab700});
}

@font-face {
  font-family: 'MuseoSlab900';
  src: url(${MuseoSlab900});
}

`;