import styled from "styled-components";
import { shade } from "polished";
import backgroundImg from "../../assets/images/background.png";

export const Background = styled.section`
  background: url(${backgroundImg});
  background-size: cover;
  background-repeat: no-repeat;
  width: 100%;
  height: 100vh;
  margin: 0 auto;
  max-width: 1920px;

  /* @media (max-width: 1440px) {
    height: 100%;
  } */
`;

export const Container = styled.div`
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 0 16px;
`;

export const ContentContainer = styled.div`
  width: 100%;
  max-width: 545px;
  height: auto;
  display: flex;
  justify-content: center;
  flex-wrap: wrap;
  background: #fff;
  border-radius: 6px;
  padding: 0 16px;
  margin: 50px 0;

  @media (min-width: 2000px) {
    height: auto;
  }

  @media (min-width: 1920px) {
    height: auto;
  }

  @media (max-width: 425px) {
    height: 780px;
  }
`;

export const Content = styled.div`
  max-width: 424px;
`;

export const Logo = styled.div`
  display: flex;
  justify-content: center;
  margin-top: 60px;
  margin-bottom: 28px;
`;

export const Title = styled.div`
  display: flex;
  justify-content: center;
  flex-wrap: wrap;
  margin-bottom: 24px;

  h1 {
    font-family: "MuseoSlab900";
    font-size: 30px;
    color: #2f2f2f;
    text-align: center;

    @media (max-width: 395px) {
      font-size: 28px;
    }
    @media (max-width: 369px) {
      font-size: 23px;
    }
  }
`;

export const Subtitle = styled.div`
  display: flex;
  justify-content: center;
  flex-wrap: wrap;
  text-align: center;
  span {
    font-family: "MuseoSlab500";
    font-size: 16px;
    color: #2f2f2f;
    display: block;
    margin-top: 16px;
    margin-bottom: 24px;
  }
`;

export const Button = styled.div`
  display: flex;
  justify-content: center;

  a {
    font-family: "MuseoSlab700";
    width: 226px;
    height: 65px;
    background: #6ac14a;
    display: flex;
    justify-content: center;
    align-items: center;
    border-radius: 6px;
    text-decoration: none;
    color: #fff;
    transition: background-color 400ms;

    &:hover {
      background: ${shade(0.2, "#6AC14A")};
    }

    svg {
      font-size: 23px;
      margin-right: 16px;
    }
  }
`;

export const Schedules = styled.div`
  display: flex;
  justify-content: center;
  flex-wrap: wrap;
  margin-top: 24px;
  margin-bottom: 32px;
  text-align: center;

  span {
    font-family: "MuseoSlab500";
    font-size: 16px;
    color: #2f2f2f;
  }
`;
export const Text = styled.div`
  width: 100%;
  display: flex;
  max-width: 219px;
  margin: 0 auto;
  text-align: center;
  p {
    font-family: "MuseoSlab700";
    font-size: 19px;
    color: #2f2f2f;
  }
`;
export const Address = styled.div`
  margin-top: 16px;
  display: flex;
  justify-content: space-between;
  flex-wrap: wrap;
  text-align: center;
  span {
    font-family: "MuseoSlab500";
    max-width: 350px;
    font-size: 14px;
    color: #2f2f2f;
    margin-right: 25px;
    display: flex;
    justify-content: center;
    margin: 0 auto;
    padding-bottom: 16px;

    @media (max-width: 425px) {
      margin: 0 auto;
    }
  }

  a {
    font-family: "MuseoSlab900";
    text-transform: uppercase;
    width: 87px;
    height: 30px;
    background: #e25e24;
    display: flex;
    justify-content: center;
    align-items: center;
    font-size: 12px;
    text-decoration: none;
    border-radius: 4px;
    color: #fff;
    margin: 0 auto;
    transition: background-color 400ms;

    &:hover {
      background: ${shade(0.2, "#E25E24")};
    }

    @media (max-width: 425px) {
      margin-top: 16px;
    }
  }
`;

export const MediasSociais = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  margin: 40px 0;

  svg {
    margin-right: 16px;
    color: #6ac14a;
    font-size: 30px;
    transition: 400ms;

    &:hover {
      color: ${shade(0.2, "#6AC14A")};
    }
  }
`;
